<template>
    <div class="d-block w-100"
         :class="{ 'position-relative': isDesktop }">
        <div v-show="!showSearch"
             v-if="isDesktop"
             class="d-flex justify-content-center align-items-center position-relative advanced-search"
             @click="activateSearch">
            <svg xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 height="16"
                 width="16"
                 viewBox="0 0 24 24"
                 class="search-icon">
                <path d="M16.4153 16.4153L20 20M18.5455 11.2727C18.5455 15.2893 15.2894 18.5454 11.2728 18.5454C7.25612 18.5454 4 15.2893 4 11.2727C4 7.2561 7.25612 4 11.2728 4C15.2894 4 18.5455 7.2561 18.5455 11.2727Z"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
            </svg>
            <div class="text">
                <translate translation-key="header.searchPlaceholder"
                           :replacements="{name: searchPlaceholders[0]}" />
            </div>
            <div class="search-trigger">
                /
            </div>
        </div>
        <div v-if="!isDesktop"
             class="mobile-advanced-search d-flex justify-content-center align-items-center">
            <svg :class="{ 'd-none': showSearch }"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 height="27"
                 width="27"
                 stroke="#c7cbe2"
                 viewBox="0 0 24 24"
                 class="search-icon mr-2"
                 @click="activateSearch">
                <path d="M16.4153 16.4153L20 20M18.5455 11.2727C18.5455 15.2893 15.2894 18.5454 11.2728 18.5454C7.25612 18.5454 4 15.2893 4 11.2727C4 7.2561 7.25612 4 11.2728 4C15.2894 4 18.5455 7.2561 18.5455 11.2727Z"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
            </svg>
            <div :class="{ 'd-none': !showSearch }"
                 class="nav-search"
                 @click="closeSearch">
                <div id="search-wrapper"
                     class="active mr-2">
                    <div class="container">
                        <div class="search-toggle" />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="showSearch"
             class="search-dropdown"
             :class="{ 'mobile-search-dropdown': !isDesktop, 'active': showSearch }">
            <div class="d-flex justify-content-between align-items-center position-sticky search-block border-bottom mb-1">
                <input id="search"
                       ref="searchInput"
                       v-model="searchQuery"
                       :placeholder="getTrans('header.searchPlaceholder',
                                              `Search ${searchPlaceholders[0]}`,
                                              {name: searchPlaceholders[0]})"
                       class="mb-0 form-control">
                <div class="close-icon"
                     @click="closeSearch">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         fill="currentColor"
                         height="12"
                         width="12"
                         viewBox="0 0 24 24"
                         class="sc-1pyr0bh-0 KQOVh">
                        <path d="M18 6L6 18M18 18L6 6"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                        <svg />
                    </svg>
                </div>
            </div>
            <div class="search-dropdown-inner">
                <div class="w-100 text-center search-loader"
                     :class="[{ 'd-block': loading }, 'd-none']">
                    <div class="wrp">
                        <Loader size="48px"
                                :text="getTrans('cryptocurrencies.loading', 'Loading...')" />
                    </div>
                </div>
                <div class="search-results">
                    <div v-if="resultsCount > 0">
                        <div v-for="(category, index) in assets"
                             :key="index">
                            <div v-if="category.length > 0">
                                <div class="d-flex justify-content-between align-items-center search-header pb-1 mt-2">
                                    <span class="category">
                                        <translate :translation-key="'header.' + index" />
                                    </span>
                                </div>
                                <a v-for="asset in category"
                                   :key="asset.ranking_general"
                                   :href="asset.url"
                                   class="search-result">
                                    <div class="coin-info w-75">
                                        <img v-if="asset.logo"
                                             :src="asset_cdn(asset.logo, 22)"
                                             class="asset-logo asset-logo__24"
                                             width="24"
                                             height="24"
                                             :alt="asset.name"
                                             :title="asset.name">
                                        <div v-else
                                             class="asset-logo-default" />
                                        <span class="name">
                                            {{ asset.name }}
                                        </span>
                                        <span v-if="index === 'cryptocurrencies'"
                                              class="coin-item-symbol">
                                            {{ asset.currency_code }}
                                        </span>
                                        <span v-if="index === 'dapps'"
                                              class="coin-item-symbol text-uppercase">
                                            {{ asset.protocol_slug }}
                                        </span>
                                    </div>
                                    <div v-if="asset.ranking_general"
                                         class="coin-rank d-flex justify-content-end">
                                        #{{ asset.ranking_general }}
                                    </div>
                                </a>
                                <div class="text-center see-more-block">
                                    <a :href="route(getRouteByIndex(index)) + (index === 'cryptocurrencies' ? ('#' + index) : '')"
                                       class="category link text-capitalize"
                                       @click="closeSearch">
                                        <translate translation-key="cryptocurrencies.seeMore" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="newsArticles?.length > 0"
                             class="news-search">
                            <div class="d-flex justify-content-between align-items-center pb-1 mt-2">
                                <span class="category">
                                    <translate translation-key="header.cryptoNews" />
                                </span>
                            </div>
                            <a v-for="newsArticle in newsArticles"
                               :key="newsArticle.id"
                               :href="newsArticle.url"
                               class="news-search-result">
                                <div class="news-info">
                                    <img v-if="newsArticle.featured_image_url"
                                         :src="asset_cdn(newsArticle.featured_image_url, 120)"
                                         class="news-image"
                                         :alt="newsArticle.title"
                                         :title="newsArticle.title">
                                    <div class="d-flex flex-column pl-2">
                                        <div class="news-category mb-1">
                                            {{ formattedCategory(newsArticle.category) }}
                                        </div>
                                        <div class="news-title">
                                            {{ newsArticle.title }}
                                        </div>
                                        <div class="date">
                                            {{ formattedDate(newsArticle.published_at) }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="text-center see-more-block">
                                <a :href="route('news')"
                                   class="category link text-capitalize">
                                    <translate translation-key="cryptocurrencies.seeMore" />
                                </a>
                            </div>
                        </div>
                        <div v-if="videos?.length > 0"
                             class="news-search">
                            <div class="d-flex justify-content-between align-items-center pb-1 mt-2">
                                <span class="category">
                                    <translate translation-key="header.cryptoVideos" />
                                </span>
                            </div>
                            <a v-for="video in videos"
                               :key="video.id"
                               :href="route('videos') + '?video-id=' + video.video_id"
                               class="news-search-result">
                                <div class="news-info">
                                    <img v-if="video.thumbnail_url"
                                         :src="asset_cdn(video.thumbnail_url, 120)"
                                         class="news-image"
                                         :alt="video.title"
                                         :title="video.title">
                                    <div class="d-flex flex-column pl-2">
                                        <div class="news-category mb-1">
                                            <translate translation-key="videos.video" />
                                        </div>
                                        <div class="news-title">
                                            {{ video.title }}
                                        </div>
                                        <div class="date">
                                            {{ formattedDate(video.published_at) }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="text-center see-more-block">
                                <a :href="route('videos')"
                                   class="category link text-capitalize">
                                    <translate translation-key="cryptocurrencies.seeMore" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else
                         class="no-results">
                        <translate translation-key="header.searchNoResults" />
                    </div>
                </div>
                <div :class="['gradient-background bottom', { 'show': !isScrolled }]" />
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { route, asset_cdn } from '../../helpers/helpers';
    import { getTrans } from '../../helpers/getTrans';
    import { useMenuStore } from '../../store/menu/MenuStore';
    import Loader from '../../components/Loader.vue';

    export default {
        name: 'GlobalSearch',
        components: { Loader },
        data() {
            return {
                assets: {},
                newsArticles: {},
                videos: {},
                resultsCount: 1,
                searchQuery: '',
                searchPlaceholders: [
                    getTrans('header.cryptos'),
                    getTrans('header.exchanges'),
                    getTrans('header.dapps'),
                    getTrans('header.nftCollections'),
                    getTrans('header.cryptoNews'),
                    getTrans('header.cryptoVideos'),
                ],
                showSearch: false,
                isScrolled: false,
                isDesktop: true,
                loading: false,
                firstLaunch: false,
                assetsRouteMapping: {
                    cryptocurrencies: 'index',
                    exchanges: 'exchanges-index',
                    dapps: 'dapp-rankings',
                    nftCollections: 'nft-collection-rankings',
                },
            };
        },
        watch: {
            searchQuery(newSearchQuery) {
                if (this.debouncedSearch) {
                    clearTimeout(this.debouncedSearch);
                }
                this.debouncedSearch = setTimeout(() => {
                    this.search(newSearchQuery);
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }, 500);
            },
        },
        created() {
            this.checkWindowSize();
            window.addEventListener('resize', this.checkWindowSize);
            window.addEventListener('keydown', (event) => {
                if (event.keyCode === 191) {
                    event.preventDefault();
                    this.activateSearch();
                }
                if (event.keyCode === 27) {
                    event.preventDefault();
                    this.closeSearch();
                }
            });
            window.addEventListener('click', (event) => {
                if (!(event.target.closest('.search-icon') || event.target.closest('.search-dropdown') || event.target.closest('.advanced-search') || event.target.closest('.countdown-container'))) {
                    this.closeSearch();
                }
            });
            this.firstLaunch = true;
        },
        destroyed() {
            window.removeEventListener('resize', this.checkWindowSize);
        },
        mounted() {
            window.setInterval(() => {
                this.changeSearchPlaceholderText();
            }, 4000);
            const searchDropdownInner = document.querySelector('.search-dropdown-inner');
            searchDropdownInner.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            const searchDropdownInner = document.querySelector('.search-dropdown-inner');
            searchDropdownInner.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            getTrans,
            search() {
                if (this.firstLaunch) {
                    this.loading = true;
                    window.setTimeout(() => {
                        this.loading = false;
                    }, 500);
                    this.firstLaunch = false;
                }
                axios.get(`/api/search?search=${this.searchQuery}`)
                    .then((res) => {
                        this.assets = res.data.assets;
                        this.newsArticles = res.data.newsArticles;
                        this.videos = res.data.videos;
                        this.resultsCount = res.data.resultsCount;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            activateSearch() {
                const menuStore = useMenuStore();

                menuStore.isMobileNavOpen = false;
                this.search();
                this.showSearch = true;
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                });
            },
            closeSearch() {
                this.showSearch = false;
                this.searchQuery = '';
            },
            getRouteByIndex(index) {
                return this.assetsRouteMapping[index];
            },
            handleScroll(event) {
                const searchDropdownInner = event.target;
                const scrollHeight = searchDropdownInner.scrollHeight;
                const visibleHeight = searchDropdownInner.clientHeight;
                const scrollTop = searchDropdownInner.scrollTop;
                this.isScrolled = scrollTop + visibleHeight >= scrollHeight;
            },
            formattedCategory(category) {
                return category.replace(/-/g, ' ');
            },
            formattedDate(date) {
                const options = { month: 'long', day: 'numeric', year: 'numeric' };
                const dateObject = new Date(date);

                return dateObject.toLocaleDateString('en-US', options);
            },
            changeSearchPlaceholderText() {
                const placeholder = this.searchPlaceholders.shift();
                this.searchPlaceholders = this.searchPlaceholders.concat(placeholder);
            },
            checkWindowSize() {
                this.isDesktop = window.innerWidth >= 1240;
            },
            route,
            asset_cdn,
        },
    };
</script>
